@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.2.1/css/fix.tca.min.css");
@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.2.1/css/animations.tca.min.css");
/* Generated by Glyphter (http://www.glyphter.com) on  Sat Nov 12 2022*/
/*@font-face {
    font-family: 'SWS';
    src: url('../assets/fonts/sws/SWS.eot');
    src: url('../assets/fonts/sws/SWS.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/sws/SWS.woff') format('woff'),
         url('../assets/fonts/sws/SWS.ttf') format('truetype'),
         url('../assets/fonts/sws/SWS.svg#SWS') format('svg');
    font-weight: normal;
    font-style: normal;
}
.NameLogo {
    display: block;
    position: relative;
    width: 200px;
    height: 100px;
    margin: 1rem auto;
    display: flex;

}
[class*='icon-']:before{
    display: inline-block;
   font-family: 'SWS';
   position: absolute;
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-size: 8rem;
   width: 66px;
    background: linear-gradient(90deg, #A0A0A0 0%, rgba(160, 160, 160, 0) 100%);
        background-size: 100px;
    color: transparent;
    -webkit-background-clip: text;
    text-shadow: none;
}
.icon-Group-1:before{ left: 0; content:'\0043'; -webkit-transform: scaleX(-1); transform: scaleX(-1); }
.icon-Group-2:before{ left: 66px; content:'\0042'; }
.icon-Group-3:before{ right: 0; content:'\0043'; }*/
/* FONTS */

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 200;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-ExtraLight.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-ExtraLight.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 200;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-ExtraLightIt.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-ExtraLightIt.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Light.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Light.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 300;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-LightIt.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-LightIt.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Regular.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Regular.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 400;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-It.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-It.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Semibold.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Semibold.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 600;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-SemiboldIt.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-SemiboldIt.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Bold.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Bold.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 700;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-BoldIt.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-BoldIt.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Black.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Black.otf.woff') format('woff');
    }

    @font-face{
        font-family: 'Source Sans 3';
        font-weight: 900;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-BlackIt.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/OTF/SourceSans3-BlackIt.otf.woff') format('woff');
    }


    @font-face{
        font-family: 'Source Sans 3 VF';
        font-weight: 200 900;
        font-style: normal;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/VAR/SourceSans3VF-Roman.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/VAR/SourceSans3VF-Roman.ttf.woff') format('woff'),
        url('../assets/fonts/source-sans/VAR/SourceSans3VF-Roman.ttf') format('truetype');
    }

    @font-face{
        font-family: 'Source Sans 3 VF';
        font-weight: 200 900;
        font-style: italic;
        font-stretch: normal;
        src: url('../assets/fonts/source-sans/WOFF2/VAR/SourceSans3VF-Italic.ttf.woff2') format('woff2'),
        url('../assets/fonts/source-sans/WOFF/VAR/SourceSans3VF-Italic.ttf.woff') format('woff'),
        url('../assets/fonts/source-sans/VAR/SourceSans3VF-Italic.ttf') format('truetype');
    }

/* FIX */

    *::-webkit-scrollbar { display: none; }
    *::-webkit-scrollbar-button { display: none; }
    *::-webkit-scrollbar-track-piece { display: none; }
    *::-webkit-scrollbar-thumb { display: none; }
    *::-webkit-resizer { display: none; }

    *, *::after, *::before {
        box-sizing: border-box;
        font-family: inherit;
        color: inherit;
    }

    body {
        font-family: 'Source Sans 3', 'apple-system', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: black;
        color: white;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    a:visited, a:focus {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;
    }

    img, video {
        width: auto;
        max-width: 100%;
    }

    [class^="col-"] {
        width: 100%;
    }

    /* root */

    :root {
        --color-base-text: 255, 255, 255;
        --color-base-accent-1: 126, 126, 126;
        --color-base-accent-2: 33, 50, 149;
        --color-base-background-1: 0, 0, 0;
        --color-base-background-2: 32, 48, 136;
        --initial-opacity: 0.85;
        --focused-opacity: 1;
        --faded-opacity: 0.65;
    }

    .responsive {
        width: 100%;
        height: auto;
    }

    a:hover {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;
    }

    button[disabled] {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.35);
        transform: scale(1.01);
    }

    .link {
        font-size: 3rem;
        padding: 1rem 2rem ;
    }

    #root {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        max-height: 100vh;
    }

    #Stars circle {
        fill: #ffffff;
    }

    /* WALLET */

    .top-connect {
        position: absolute;
        z-index: 13;
        right: 2rem;
        top: 2.75rem;
    }

    @media screen and ( min-width: 749px ) {
        .top-connect {
            right: 1.5rem;
            top: 1.5rem;
        }
    }

    /* Content */

    .Base .viewport {
        position: relative;
        height: 100vh;
        position: relative;
        z-index: 4;
    }

    /* CONTENT */

    .RouteWrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        max-height: 100vh;
    }

    .TopContainer {
        position: absolute;
        top: 0;
    }

    /* CONTENT */

    .Base {
        position: relative;
        z-index: 9;
        min-height: 100vh;
    }

    .container {
        position: relative;
    }

    .container.Mint {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    @media screen and ( max-width: 750px ) {

        .container.Mint {
            text-align: left;
            max-width: 90vw;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .btn--primary {
        border: 1px solid rgba(255, 255, 255, 0.95);
        font-size: 1.5rem;
        font-weight: 700;
        height: 45px;
        padding: 0 2.4rem;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.95);
        transition: all ease 100ms;
        text-shadow: 2px 2px 2px #818181;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);
        text-transform: uppercase;
        width: 100%;
        cursor: pointer;
        position: relative;
        z-index: 0;
        
        
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .btn--primary:hover {
        border: none;
        outline: none;
        font-weight: bold;
        background: transparent;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 0px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        animation: glowing-product-form__submit 20s linear infinite;
    }

    .btn--primary:before {
        content: "";
        background: linear-gradient(
            45deg,
            #2a2a2a,
            #343434,
            #5e5e5e,
            #818181
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(0px);
        -webkit-filter: blur(0px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-product-form__submit 20s linear infinite;
        transition: all 0.3s ease-in-out;
        border-radius: 0px;
    }

    .btn--primary:hover:before {
        content: "";
        background: linear-gradient(
            45deg,
            #2a2a2a,
            #343434,
            #5e5e5e,
            #818181
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-product-form__submit 20s linear infinite;
        transition: all 0.3s ease-in-out;
        border-radius: 0px;
    }

    .btn--primary:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        left: 0;
        top: 0;
        border-radius: 0px;
    }

    @keyframes glowing-product-form__submit {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }

    @-webkit-keyframes glowing-product-form__submit {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }

    .NFTContainer {
        display: none;
    }

    .NFTContainer.success {
        display: block;
    }

    button:not(:disabled):hover {
        transform: scale(0.98)
    }

    button[data-mint-loading="true"] {
        background: rgba(22, 25, 31, 0.24);
        color: white;
    }

    button[data-mint-started="true"] {
        position: relative;
    }

@-webkit-keyframes pulseIn {
    from {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }

    50% {
        -webkit-transform: scale3d(1,0.95,0.95);
        transform: scale3d(1,0.95,0.95)
    }

    to {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }
}

@keyframes pulseIn {
    from {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }

    50% {
        -webkit-transform: scale3d(1,0.95,0.95);
        transform: scale3d(1,0.95,0.95)
    }

    to {
        -webkit-transform: scale3d(1,1,1);
        transform: scale3d(1,1,1)
    }
}

    button[data-mint-started="true"]::after {
        animation-name: pulseIn;
        animation-duration: 500ms;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        border-radius: inherit;
        bottom: 0;
        content: ' ';
        left: 0;
        position: absolute;
        line-height: 3.4rem;
        right: 0;
        top: 0;
        overflow: visible;
    }

    button[data-mint-done="true"] {
        opacity: 0;
        transform: scale(0.85);
    }

/* MINTING */

    .Base.minting,
    .Button.minting,
    .Mint.minting { 
        opacity: 1;
    }

    .container.minting { 
        transform: scale(1.05);
    }

    @keyframes appear {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes appear {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }


    .success > div > p:first-child, 
    .success > div > img,
    .success > div > p:last-child {
        opacity: 0;
    }


    .Mint.success > div > p:first-child, 
    .Mint.success > div > img,
    .Mint.success > div > p:last-child {
        opacity: 0;
        animation-name: appear;
        animation-duration: 2s;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    /*  MINT ANIMATION */

    .psych {
        opacity: 0;
        min-height: 65vh;
        position: absolute;
        min-width: 100vw;
        z-index: -1;
        transition: opacity 900ms linear, transform 400ms linear, top 400ms linear;
        will-change: transform, opacity;
    }

    .psych.minting {
        top: 0;
        opacity: 1;
    }

    @media screen and ( max-width: 749px ) {
        
        .psych {
            left: -50vw;
            width: 200vw;
            left: -50vw;
            top: 0;
        }
        
        .psych.minting {
            opacity: 1;
        }
    }

    .background-container {
        z-index: -2;
    }

    .background-mint {
        position: absolute!important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 100vh;
        min-width: 100vw;
        height: auto;
        max-width: unset;
    }

    .overlayCard {
        position: absolute;
        bottom: 2.5rem;
        width: 100%;
        padding: 0 1rem;
    }

    .NFTContainer {
        transition: opacity 200ms linear; 
    }

    .NFTContainer.minting {
        opacity: 0;
    }

    .NFTContainer.success {
        position: absolute;
        opacity: 1;
        right: 0;
        top: -100vh;
        -webkit-animation-duration: 15s;
        animation-duration: 15s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-name: slowlyDown;
        animation-name: slowlyDown;
    }

    .NFTContainer.success {
        color: #fff;
    }

    .NFTContainer.success > div {
        display: block;
        
        border: 2px solid;
        border-top: 0;
        margin-top: 1rem;
        z-index: 3;
        border-radius: 150px;
    }

    .NFTContainer.success > div::before, .NFTContainer.success > div::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
    }

    .NFTContainer.success > div::before {
        width: 2px;
        height: 5px;
        top: calc(100% + 1px);
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff;
    }

    .NFTContainer.success > div::after {
        border: 2px solid;
        width: 8px;
        height: 18px;
        left: 50%;
        top: -10px;
        border-radius: 4px;
        transform: translate(-50%, 0);
    }

    .NFTContainer.success > div:after {
        width: 250px;
        height: 250px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        z-index: 1;
        box-shadow: 10px -55px 30px 15px #823ca6, 24px -10px 47px 10px #aab3d2, -21px -25px 97px 10px #5acee3, 51px 5px 17px 10px #1b7d8f, 3px 2px 77px 10px #f30bf5;
        transform-origin: center;
    }

    @-webkit-keyframes slowlyDown {
        0%   { top: -60vh; }
        100% { top: 0vh; }
    }
    @-moz-keyframes slowlyDown {
        0%   { top: -60vh; }
        100% { top: 0vh; }
    }
    @-o-keyframes slowlyDown {
        0%   { top: -60vh; }
        100% { top: 0vh; }
    }
    @keyframes slowlyDown {
        0%   { top: -60vh; }
        100% { top: 0vh; }
    }

    .Mint.container.idle, .Mint.container.minting  {
        box-shadow: none;
        border: none;
        background-color: transparent;
        background-image: none;
        text-align: center;
        backdrop-filter: blur(0px);
    }

    .Mint.container.idle button.idle, .Mint.container.minting button.idle,
    .Mint.container.idle p, .Mint.container.minting p {
        margin-left: auto;
        margin-right: auto;
    }

    .Mint.container.idle h1, .Mint.container.minting h1,
    .Mint.container.idle p, .Mint.container.minting p {
        display: none;
    }

    .logo {
      transform-style: preserve-3d;
      animation: opac 5s infinite linear -7.5s;
      animation-fill-mode: both;
    }


    @keyframes opac {
        0% {
            opacity: 0.8;
        }

        50% {
            opacity: 0.45;
        }

        to {
            opacity: 0.8;
        }
    }

    @-webkit-keyframes opac {
        0% {
            opacity: 0.8;
        }

        50% {
            opacity: 0.45;
        }

        to {
            opacity: 0.8;
        }
    }

    .home-content {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: scroll;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }

    @supports (-webkit-touch-callout: none) {

        .home-content {
            height: -webkit-fill-available;
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        height: -webkit-fill-available;
        justify-content: center;
        row-gap: 5vh;
    }

    @media screen and ( max-width: 749px ) { 
        .main-content {
            justify-content: center;
            
        }
    }

    .home-content > .col-12 {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100vw + 3rem);
    }

    .content__title {
        position: relative;
        display: block;
        width: 100vw;
        margin: 0;
        white-space: nowrap;
        text-align: center;
    }

    .content__title .title__text {
        position: relative;
        display: block;
        height: 0;
        opacity: 0;
        width: fit-content;
        margin: 0 auto;
        padding: 0;
        font-size: 3rem;
        line-height: 8rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        background:linear-gradient(#262626,#888888,#262626);
        -webkit-background-clip: text;
        color:transparent;
    }
     .content__title .title__text:after {
        content: "slayworld";
        position: absolute;
        left: 0;
        z-index: -1;
        text-shadow:    1px 1px 0 #262626, 2px 2px 0 #262626, 3px 3px 0 #262626,
                        4px 4px 0 #262626, 5px 5px 0 #262626, 1px 1px 0 #262626, 6px 20px 20px rgba(0,0,0,1),
                        -1px -1px 0 #ddd;
    }

    .content__image {
        z-index: -2;
    }

    @media screen and ( min-width: 750px ) {
        
        .content__title .title__text {
            height: 12rem;
            margin: 0 auto -4rem auto;
            font-size: 6rem;
            line-height: 12rem;
            letter-spacing: 0.35rem;
        }
    }

    .content__text.welcome {
        padding: 0 0 0 2rem;
        justify-content: space-between;
    }

    @media screen and (min-width: 750px) {
        
        .content__text.welcome {
            position: absolute;
            background: rgba(var(--color-base-background-1), 0.95);
            width: 31rem;
            bottom: 0;
            padding: 0;
        }
    }

    .content__text.welcome .col-10 {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgb(var(--color-base-text));
    }

    .content__text.welcome .col-2 {
        position: relative;
        width: 100%;
    }

    .content__text.welcome .rotate {
        position: absolute;
        transform: translateX(-50%) rotate(-90deg);
        width: max-content;
        top: 50%;
        left: 50%;
        font-size: 1.25rem;
    }

    .content__text.welcome .common-title {
        transform: scale(1, 0.85);
        line-height: 3.65rem;
    }

    .content__text.welcome + div p {
        font-size: 1.65rem;
        line-height: 2.85rem;
    }

    .content__text.welcome .copyright {
        justify-content: center;
        height: -webkit-fill-available!important;
        display: flex;
        align-items: flex-end;
    }

    video {
        touch-action: none;
    }

    video::-webkit-media-controls {
        display: none;
        touch-action: none;
    }

